import React, { useState } from 'react';
import axios from 'axios';
import { Container, Grid, Card, Typography, TextField, Button, Box } from '@mui/material';
import { BASE_URL } from '../../../../Services';
import Header from '../../../Components/HeaderComponent'; // Adjust path as needed
import Footer from '../../../Components/FooterCompnent'; // Adjust path as needed

const Contact = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    message: '',
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/contacts`, formData);
      setMessage(response.data.message);
      setFormData({ username: '', email: '', message: '' });
    } catch (error) {
      console.error('Error submitting contact form:', error);
    }
  };

  return (
    <div>
      <Header />
      <div style={{height:"15vh"}}/>
      <Container sx={{ marginTop: 5, marginBottom: 5 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={8}>
            <Card sx={{ padding: 4, borderRadius: 3, boxShadow: 3 }}>
              <Typography variant="h4" align="center" gutterBottom>
                Contact Us
              </Typography>
              {message && (
                <Typography variant="body1" color="success.main" align="center" gutterBottom>
                  {message}
                </Typography>
              )}
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  label="Username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  fullWidth
                  required
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  required
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  fullWidth
                  required
                  margin="normal"
                  multiline
                  rows={4}
                  variant="outlined"
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    marginTop: 2,
                    padding: 1.5,
                    borderRadius: 2,
                    textTransform: 'none',
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default Contact;
