import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';
import axios from 'axios';

import Loader from '../../UserSide/Components/LoaderComponent';
import Sidebar from '../sidebar/Sidebar';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
} from '@mui/material';
import { People, Paid, AttachMoneyOutlined, Receipt } from '@mui/icons-material';
import ReceiptIcon from '@mui/icons-material/Receipt';

const UserStats = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [products, setProducts] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [participants, setParticipants] = useState([]);
  const [paidParticipants, setPaidParticipants] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { userData } = useUser();

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  const fetchUserStats = async () => {
    
    try {
      const usersResponse = await axios.get(`${BASE_URL}/users`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setTotalUsers(usersResponse.data.length);

      const participantsResponse = await axios.get(`${BASE_URL}/participants`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const participantsData = participantsResponse.data;
      setParticipants(participantsData);

      const paid = participantsData.filter((participant) => participant.total_amount > 0);
      setPaidParticipants(paid.length);

      const totalSales = participantsData.reduce(
        (sum, participant) => sum + (parseFloat(participant.total_amount) || 0),
        0
      );
      setTotalAmount(totalSales);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/products`, {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });
        if (response.data.status === 200) {
          setProducts(response.data.products);
        } else {
          console.error('Failed to fetch products');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, [userData]);

  useEffect(() => {
    fetchUserStats();
    const interval = setInterval(() => {
      fetchUserStats();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
          <div className="dashboard-content p-4">
            <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 bg-light rounded text-left">User Stats</h1>
            {loading ? (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            ) : (
              <>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        borderRadius: '16px',
                        boxShadow: 3,
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          boxShadow: 6,
                        },
                      }}
                    >
                      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar sx={{ bgcolor: '#3f51b5', mr: 2, mb: 6 }}>
                          <People />
                        </Avatar>
                        <Box>
                          <Typography variant="h5" sx={{ display: 'block', lineHeight: 2 }}>
                            Total Users
                          </Typography>
                          <Typography variant="h4" fontWeight="bold" sx={{ mt: 0.5 }}>
                            {totalUsers}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        borderRadius: '16px',
                        boxShadow: 3,
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          boxShadow: 6,
                        },
                      }}
                    >
                      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar sx={{ bgcolor: '#f57c00', mr: 2, mb: 6 }}>
                          <Paid />
                        </Avatar>
                        <Box>
                          <Typography variant="h5" sx={{ display: 'block', lineHeight: 2 }}>
                            Paid Participants
                          </Typography>
                          <Typography variant="h4" fontWeight="bold" sx={{ mt: 0.5 }}>
                            {paidParticipants}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        borderRadius: '16px',
                        boxShadow: 3,
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          boxShadow: 6,
                        },
                      }}
                    >
                      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar sx={{ bgcolor: '#009688', mr: 2, mb: 6 }}>
                          <AttachMoneyOutlined />
                        </Avatar>
                        <Box>
                          <Typography variant="h5" sx={{ display: 'block', lineHeight: 2 }}>
                            Total Sales
                          </Typography>
                          <Typography variant="h4" fontWeight="bold" sx={{ mt: 0.5 }}>
                            PKR. {totalAmount.toFixed(2)}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            <Box sx={{ mt: 12 }}>
              <h1 className="fw-bold text-dark shadow-sm p-3 mb-4 bg-light rounded text-left">Products</h1>
              <Grid container spacing={3}>
                {products.map((product) => (
                  <Grid item xs={12} sm={6} md={4} key={product.id}>
                    <Card
                       sx={{
                        borderRadius: '16px',
                        boxShadow: 3,
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          boxShadow: 6,
                        },
                      }}
                    >
                      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar sx={{ bgcolor: '#097dc4', mr: 2, mb: 6 }}>
                          <ReceiptIcon />
                        </Avatar>
                        <Box>
                        <Typography variant="h5" sx={{ display: 'block', lineHeight: 2  }}>
                          {product.name}
                        </Typography>
                        <Typography variant="h5" fontWeight="bold" sx={{ mt: 0.5 }}>
                          Sold{" "}: {product.sold || 0}
                        </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
            </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserStats;
