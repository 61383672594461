import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../Services/AuthContext';
import {
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Typography,
    Box,
    InputAdornment,
    IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function Login() {
    const [emailOrPhone, setEmailOrPhone] = useState('');
    const [password, setPassword] = useState('');
    const [emailOrPhoneError, setEmailOrPhoneError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setEmailOrPhoneError('');
        setPasswordError('');

        try {
            const response = await login({ email_or_phone: emailOrPhone, password });

            if (response?.status === 200) {
                if (response?.user?.role === 'admin' || response?.user?.role === 'editor') {
                    navigate('/dashboard');
                } else {
                    navigate('/');
                }
                window.location.reload();
            }
        } catch (error) {
            if (error.response?.status === 404) {
                setEmailOrPhoneError('User with this email or phone does not exist.');
            } else if (error.response?.status === 401) {
                setPasswordError('Incorrect password. Please try again.');
            } else {
                setEmailOrPhoneError('Unexpected error occurred. Please check your details.');
            }
        }
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box
                p={3}
                bgcolor="white"
                maxWidth={500}
                width="100%"
            >
                <Box textAlign="center" mb={3}>
                    <img
                        src={require('../UserSide/images/logo.png')}
                        alt="Login Icon"
                        style={{ width: '140px', height: '65px' }}
                    />
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Email or Phone#"
                            value={emailOrPhone}
                            onChange={(e) => setEmailOrPhone(e.target.value)}
                            error={!!emailOrPhoneError}
                            helperText={emailOrPhoneError}
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={!!passwordError}
                            helperText={passwordError}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            required
                        />
                    </Box>
                    <FormControlLabel
                        control={<Checkbox />}
                        label="Remember me"
                        sx={{ justifyContent: 'flex-start', display: 'flex' }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{
                            py: 1.5,
                            fontSize: '16px',
                        }}
                    >
                        LOGIN
                    </Button>
                </form>
                <Box display="flex" justifyContent="space-between" mt={2}>
                    <Link to="/dashboard/forgot-password" style={{ fontSize: '14px', color: 'red', fontWeight: 'bold' }}>
                        Forgot password?
                    </Link>
                </Box>
            </Box>
        </Box>
    );
}

export default Login;
