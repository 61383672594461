import React from 'react'
import Header from '../../Components/HeaderComponent';
import Footer from '../../Components/FooterCompnent';
import "../../css/Styles.css"

const TermsConditions = () => {
  return (
    <div>
      <Header />
      <div style={{ height: "15vh" }} />
      <div class="container-fluid " style={{ marginBottom: "30px", marginTop: "40px" }}>
        <div class="row">
          <div class="col-lg-8 offset-lg-2 ">
            <div class="card  p-4 glow" style={{ borderRadius: 20 }}>
              <h2 class="text-center mb-4">Terms & Conditions</h2>
              <ol class="list-group list-group-flush">
                <li class="list-group-item">
                  <strong>Eligibility:</strong>
                  <p>Enaam, a skill-based contest with prizes, is available to anybody who is 18 years of age or older. The giveaway is not open to the direct family members of the giveaway organizer's employees, agents, and representatives.</p>
                </li>
                <li class="list-group-item">
                  <strong>Contestants must register:</strong>
                  <p>Contestants must register on the Enaam website or app and provide accurate and complete information in order to take part in the giveaway. Incomplete, incorrect, or illegible entries will not be accepted.</p>
                </li>
                <li class="list-group-item">
                  <strong>Competition rules:</strong>
                  <p>There are a series of questions in the competition, and only those
                    who answer the question correctly will enter the competition. The
                    participant who answers the question fastest will win the prize.
                    If one or more people get the fastest time, then a lucky draw will
                    be done among them to decide the winner.   Please Note
                    if a user delete their account, they will not be a part of this
                    Competition.</p>
                </li>
                <li class="list-group-item">
                  <strong>Prizes:</strong>
                  <p>At the end of the giveaway, the winner's award will be announced. No cash or other items may be swapped for the prize, which is non-transferable.</p>
                </li>
                <li class="list-group-item">
                  <strong>Winner Notification:</strong>
                  <p>Within 48 hours after the giveaway, the winner will be contacted by phone or email. The reward will be forfeited and a new winner will be chosen if the winner doesn't respond within 1 day of being notified.</p>
                </li>
                <li class="list-group-item">
                  <strong>Disqualification:</strong>
                  <p>The giveaway organizer reserves the right to disqualify any contestant who violates the terms and conditions of the giveaway or engages in any fraudulent activity.</p>
                </li>
                <li class="list-group-item">
                  <strong>Liability:</strong>
                  <p>The giveaway organizer is not responsible for any loss, damage, injury, or harm caused to the contestant during the giveaway. The contestant participates in the giveaway at their own risk.</p>
                </li>
                <li class="list-group-item">
                  <strong>Non-Refundable:</strong>
                  <p>There is no refund on any stage of the giveaway or in case of losing it.</p>
                </li>
                <li class="list-group-item">
                  <strong>Modification:</strong>
                  <p>The giveaway organizer reserves the right to modify, suspend, or cancel the giveaway at any time without prior notice.</p>
                </li>
                <li class="list-group-item">
                  <strong>Governing Law:</strong>
                  <p>The giveaway is governed by the laws of the country where the giveaway is being held.</p>
                </li>
                <li class="list-group-item">
                  <strong>Agreement:</strong>
                  <p>By participating in the giveaway, the contestant agrees to abide by these terms and conditions and the decisions of the giveaway organizer, which are final and binding.</p>
                </li>
                <li class="list-group-item">
                  <strong>User Account Deletion:</strong>
                  <p>If a user want to request his/her account to be deleted ,please contact at info@enaam.pk</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default TermsConditions;