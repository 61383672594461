import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../Services';
import { ToastContainer, toast } from 'react-toastify'; 
import Sidebar from './sidebar/Sidebar';
import Loader from '../UserSide/Components/LoaderComponent';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function AdminDashboard() {
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortedUsers, setSortedUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [loading, setLoading] = useState(true); 

    const entriesPerPage = 10;

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${BASE_URL}/users`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const allUsers = response.data;

                const sorted = allUsers.sort((a, b) => {
                    if (a.role === 'admin' && b.role !== 'admin') {
                        return -1;
                    } else if (a.role !== 'admin' && b.role === 'admin') {
                        return 1;
                    }
                    return 0;
                });

                setUsers(sorted);
                setTotalUsers(sorted.length);
                setSortedUsers(sorted);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        const filteredUsers = users.filter(user =>
            (user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (user.role && user.role.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (user.phone && user.phone.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        setSortedUsers(filteredUsers);
        setCurrentPage(1); 
    }, [searchTerm, users]);

    const paginatedUsers = sortedUsers.slice(
        (currentPage - 1) * entriesPerPage,
        currentPage * entriesPerPage
    );

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`${BASE_URL}/users/${userToDelete}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setUsers(users.filter(user => user.id !== userToDelete));
            setTotalUsers(totalUsers - 1);
            setShowDeleteModal(false);
            toast.success('User deleted successfully!');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error('Cannot delete user. The user is linked to other data.');
            } else {
                toast.error('An error occurred while trying to delete the user.');
            }
        }
    };

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    const downloadPdf = () => {
        const doc = new jsPDF();
        const userRows = users
            .filter(user => user.role === 'user')
            .map((user, index) => [
                index + 1,
                user.name,
                user.email,
                user.phone,
                user.role,
            ]);

        doc.autoTable({
            head: [['SR No.', 'Name', 'Email', 'Phone', 'Role']],
            body: userRows,
        });
        doc.save('Enaam_users_list.pdf');
    };

    const downloadExcel = () => {
        const userRows = users
            .filter(user => user.role === 'user')
            .map((user, index) => ({
                'SR No.': index + 1,
                Name: user.name,
                Email: user.email,
                Phone: user.phone,
                Role: user.role,
                Date: new Date(user.created_at).toLocaleDateString(),
            }));
        const worksheet = XLSX.utils.json_to_sheet(userRows);
        const columnWidths = [
            { wch: 8 }, 
            { wch: 20 }, 
            { wch: 36 }, 
            { wch: 15 },
            { wch: 10 }, 
            { wch: 15 },
        ];
        worksheet['!cols'] = columnWidths;
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
        XLSX.writeFile(workbook, 'Enaam_users_list.xlsx');
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="dashboard-content p-4">
                        <div className="total-users-box mb-4 p-3 bg-light rounded shadow-sm d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
                            <div className="d-flex align-items-center mb-3 mb-md-0">
                                 <h5 className="mb-0">Total Users: {totalUsers}</h5>
                            </div>
                            <div className="d-flex gap-2 flex-wrap justify-content-start justify-content-md-end">
                                <button className="btn btn-primary" onClick={downloadPdf}><bi className="bi bi-download"></bi> PDF</button>
                                <button className="btn btn-success" onClick={downloadExcel}><bi className="bi bi-download"></bi> Excel</button>
                            </div>
                        </div>
                        <input
                            type="text"
                            placeholder="Search users..."
                            className="form-control mb-4"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />

                        {loading ? (
                            <div className="d-flex justify-content-center">
                                <Loader />
                            </div>
                        ) : (
                            <div className="table-responsive">
                                <table className="table table-hover table-striped table-bordered shadow-sm" style={{fontSize: '14px'}}>
                                    <thead className="table-dark">
                                        <tr>
                                            <th>SR No.</th>
                                            <th>Name</th>
                                            <th>Promocodes</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Role</th>
                                            <th className="text-center">Date</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedUsers.length ? (
                                            paginatedUsers.map((user, index) => (
                                                <tr key={user.id}>
                                                    <td className="align-middle">{(currentPage - 1) * entriesPerPage + index + 1}</td>
                                                    <td className="align-middle">{user.name}</td>
                                                    <td className="align-middle">
                                                        {user.promo_codes && user.promo_codes.length > 0
                                                            ? user.promo_codes.join(', ')
                                                            : 'No Promocodes'}
                                                    </td>
                                                    <td className="align-middle">{user.email}</td>
                                                    <td className="align-middle">{user.phone}</td>
                                                    <td className="align-middle">{user.role}</td>
                                                    <td className="align-middle">{new Date(user.created_at).toLocaleDateString()}</td>
                                                    <td className="text-center align-middle">
                                                        <div className='d-flex gap-2 g-0 justify-content-center'>
                                                            <Link to={`/dashboard/users/${user.id}`} className="btn btn-primary btn-sm">
                                                                <i className="bi bi-pencil"></i>
                                                            </Link>
                                                            {user?.role !== "admin" && (    
                                                                <button
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={() => {
                                                                        setUserToDelete(user.id);
                                                                        setShowDeleteModal(true);
                                                                    }}
                                                                >
                                                                    <i className="bi bi-trash"></i>
                                                                </button>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">No users found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        <Stack spacing={2} className="d-flex justify-content-center align-items-center mt-4">
                            <Pagination
                                count={Math.ceil(sortedUsers.length / entriesPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                variant='outlined'
                                color='primary'
                            />
                        </Stack>

                        {showDeleteModal && (
                            <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Confirm Deletion</h5>
                                            <button type="button" className="btn-close" onClick={() => setShowDeleteModal(false)}></button>
                                        </div>
                                        <div className="modal-body">
                                            <p>Are you sure you want to delete this user?</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primary" onClick={() => setShowDeleteModal(false)}>
                                                Cancel
                                            </button>
                                            <button type="button" className="btn btn-danger" onClick={handleDelete}>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer /> 
        </div>
    );
}

export default AdminDashboard;
