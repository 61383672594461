import React from 'react';
import './error.css'; 

const Error = () => {
  return (
      <div className='container-fluid col-lg-12 '>
        <section className='page_404'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='col-sm-12 col-sm-offset-1 text-center'>
                  <div className='four_zero_four_bg'></div>
                  <div className='contant_box_404 mt-1'>
                    <h3 className='h2'>Look like you're lost</h3>
                    <p>The page you are looking for is not available!</p>
                    <a href='/' className='link_404'>Go to Home</a>
                  </div>
                </div>
              </div>
            </div>
        </section>
      </div>
   
  );
};

export default Error;
