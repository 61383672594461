
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const YouTubePlaylistEmbed = () => {
    const playlistId = "PLwU9pC2C0PwHDx6gQLX5u5ZwevKafip8-";
    const apiKey = "AIzaSyA3YE4l_RpUdKOCQ-i5F8H6rT3iN1cY-hY"; // Your API Key
    const [videoIds, setVideoIds] = useState([]);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await axios.get('https://www.googleapis.com/youtube/v3/playlistItems', {
                    params: {
                        part: 'snippet',
                        maxResults: 50, // Adjust this number based on how many videos you want
                        playlistId: playlistId,
                        key: apiKey,
                    },
                });

                // Extract video IDs from the response and reverse the order
                const ids = response.data.items.map(item => item.snippet.resourceId.videoId).reverse();
                setVideoIds(ids);
            } catch (error) {
                console.error('Error fetching videos:', error);
            }
        };

        fetchVideos();
    }, [playlistId, apiKey]);

    return (
        <div style={{ textAlign: 'center' }}>
            <h1>Embedded YouTube Playlist in Reverse Order</h1>
            {videoIds.map(videoId => (
                <div key={videoId} style={{ marginBottom: '20px' }}>
                    <iframe
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        title={`YouTube Video ${videoId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            ))}
        </div>
    );
};

export default YouTubePlaylistEmbed;
