

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../../Services';
import Loader from '../../../Components/LoaderComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const HangWinnerScreen = () => {
    const [winners, setWinners] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchWinners = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/hang-tower-winners`);
                setWinners(response.data.winners);
            } catch (error) {
                console.error('Error fetching winners:', error);
                toast.error(`Error fetching winners: ${error.response?.data?.message || error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchWinners();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${BASE_URL}/hang-tower-winners/${id}`);
            setWinners(winners.filter(winner => winner.id !== id));
        } catch (error) {
            console.error('Error deleting winner:', error);
            toast.error(`Error deleting winner: ${error.response?.data?.message || error.message}`);
        }
    };

    if (loading) return (
        <div className="container-fluid">
            <div className="d-flex justify-content-center">
                <Loader />
            </div>
        </div>
    );

   
    const extendedWinners = [
        winners[winners.length - 1],
        ...winners,
        winners[0]
    ].filter(Boolean); 

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };

    return (
        <div className="container-fluid mt-5 mb-4">
            <h3 className="title-style mb-4" style={{ fontWeight: "bold"}}>Hang Tower Winners</h3>
            <div className="row mt-5">
                {winners.length > 0 ? (
                    <Carousel
                        style={{}}
                        responsive={responsive}
                        ssr={true}
                        infinite={true} 
                        autoPlay={true} 
                        autoPlaySpeed={100} 
                        keyBoardControl={false} 
                        customTransition="transform 1000ms ease-in-out"
                        transitionDuration={2000} 
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={['tablet', 'mobile' , 'desktop']} 
                        itemClass="carousel-item-padding-40-px" 
                        additionalTransfrom={0} 
                    >
                        {extendedWinners.map((winner, index) => (
                            <div key={index} className="col-md-12 col-lg-12 col-10 mb-4 ms-lg-0 ms-md-0 ms-4 p-2">
                                <div className='card glow glow-card' style={{borderRadius:"15px",height:"390px"}}>
                                         <img  
                                            src={winner?.image} 
                                            alt={winner?.participant?.invoice?.user?.name} 
                                            style={{ width: '100%', height: '240px', borderRadius: '5px' }} 
                                        />
                                    <div className='card-body pt-0' >
                                       <div className="card-text mt-4" style={{ fontSize: '14px' }}>
                                            <div className="d-flex justify-content-start gap-md-2 mb-2">
                                                <div className="col-3 col-md-4 text-left p-0 fw-bolder">Name:</div>
                                                <div className="col-9 text-left p-0 ms-1">{winner?.name}</div>
                                            </div>
                                            <div className="d-flex justify-content-start mb-2 gap-md-2">
                                                <div className="col-3 col-md-4 text-left p-0 fw-bolder">Prize:</div>
                                                <div className="col-9 text-left p-0 ms-1">{winner?.reward}</div>
                                            </div>
                                            <div className="d-flex justify-content-start mb-2 gap-md-2">
                                                <div className="col-3 col-md-4 text-left p-0 fw-bolder">Location:</div>
                                                <div className="col-9 col-md-8 text-left p-0 ms-1">{winner?.location}</div>
                                            </div>
                                            <div className="d-flex justify-content-start mb-2 gap-md-2">
                                                <div className="col-md-4 col-3 text-left p-0 fw-bolder">Date:</div>
                                                <div className="col-9 text-left p-0 ms-1">{winner?.draw_date}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                ) : (
                    <div className="text-center">
                        <p className="fs-5 text-danger">No winners found</p>
                    </div>
                )}
            </div>
            <ToastContainer />
        </div>
    );
};

export default HangWinnerScreen;
