import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../Services';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as XLSX from "xlsx";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Pagination } from '@mui/material';

function BrandAmbassadors() {
    const [ambassadors, setAmbassadors] = useState([]);
    const [totalAmbassadors, setTotalAmbassadors] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [referredFilter, setReferredFilter] = useState('all');
    const [amountFilter, setAmountFilter] = useState('all');
    const [sortedAmbassadors, setSortedAmbassadors] = useState([]);
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1); 
    const itemsPerPage = 5;

    useEffect(() => {
        const fetchAmbassadors = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${BASE_URL}/users`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const ambassadorUsers = response.data.filter(user => user.role === 'ambassador');
                setAmbassadors(ambassadorUsers);
                setTotalAmbassadors(ambassadorUsers.length);
                setSortedAmbassadors(ambassadorUsers);
            } catch (error) {
                console.error('Error fetching ambassadors:', error);
                toast.error('Failed to fetch ambassadors.');
            } finally {
                setLoading(false);
            }
        };
        fetchAmbassadors();
    }, []);

    useEffect(() => {
        let filteredAmbassadors = ambassadors.filter(ambassador =>
            (ambassador.name && ambassador.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (ambassador.email && ambassador.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (ambassador.phone && ambassador.phone.toLowerCase().includes(searchTerm.toLowerCase()))
        );

        if (referredFilter === 'ascending') {
            filteredAmbassadors = filteredAmbassadors.sort((a, b) => a.total_referred_users - b.total_referred_users);
        } else if (referredFilter === 'descending') {
            filteredAmbassadors = filteredAmbassadors.sort((a, b) => b.total_referred_users - a.total_referred_users);
        }

        if (amountFilter === 'greaterThanZero') {
            filteredAmbassadors = filteredAmbassadors.filter(ambassador =>
                (ambassador.referrals_list?.reduce((total, referral) => total + (referral.total_price || 0), 0) || 0) > 0
            );
        } else if (amountFilter === 'equalToZero') {
            filteredAmbassadors = filteredAmbassadors.filter(ambassador =>
                (ambassador.referrals_list?.reduce((total, referral) => total + (referral.total_price || 0), 0) || 0) === 0
            );
        }

        setSortedAmbassadors(filteredAmbassadors);
    }, [searchTerm, referredFilter, amountFilter, ambassadors]);

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    const downloadPdf = () => {
        const doc = new jsPDF();
        const ambassadorRows = sortedAmbassadors.map((ambassador, index) => [
            index + 1,
            ambassador.name,
            ambassador.email,
            ambassador.phone,
            ambassador.total_referred_users
        ]);

        doc.autoTable({
            head: [['SR No.', 'Name', 'Email', 'Phone', 'Referred Users']],
            body: ambassadorRows,
        });
        doc.save('Brand_Ambassadors_List.pdf');
    };

    const downloadExcel = () => {
        const ambassadorRows = sortedAmbassadors.map((ambassador, index) => ({
            "SR No.": index + 1,
            "Name": ambassador.name,
            "Email": ambassador.email,
            "Phone": ambassador.phone,
            "Referred Users": ambassador.total_referred_users,
            "Amount": ambassador.referrals_list.reduce((total, referral) => total + (referral.total_price || 0), 0),
        }));
        const worksheet = XLSX.utils.json_to_sheet(ambassadorRows);
        const columnWidths = [
            { wch: 8 }, 
            { wch: 20 },
            { wch: 30 },
            { wch: 15 },
            { wch: 18 }, 
            { wch: 15 },
        ];
        worksheet["!cols"] = columnWidths;
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Ambassadors");
        XLSX.writeFile(workbook, "Brand_Ambassadors_List.xlsx");
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedAmbassadors = sortedAmbassadors.slice(startIndex, startIndex + itemsPerPage);

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="dashboard-content p-4">
                        <div className="total-users-box mb-4 p-3 bg-light rounded shadow-sm d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
                            <h5 className="mb-0">Total Ambassadors: {totalAmbassadors}</h5>
                            <div className="d-flex gap-2 flex-wrap justify-content-start justify-content-md-end">
                                <button className="btn btn-primary" onClick={downloadPdf}><bi className="bi bi-download"></bi> PDF</button>
                                <button className="btn btn-success" onClick={downloadExcel}><bi className="bi bi-download"></bi>Excel</button>
                            </div>
                        </div>
                        <div className="d-flex mb-4 align-items-center">
                            <input
                                type="text"
                                placeholder="Search ambassadors..."
                                className="form-control me-3"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <select
                                className="form-control me-3"
                                value={referredFilter}
                                onChange={(e) => setReferredFilter(e.target.value)}
                            >
                                <option value="all">All Referred Users</option>
                                <option value="ascending">ASC</option>
                                <option value="descending">DESC</option>
                            </select>
                            <select
                                className="form-control"
                                value={amountFilter}
                                onChange={(e) => setAmountFilter(e.target.value)}
                            >
                                <option value="all">All Amount</option>
                                <option value="greaterThanZero">MAX Amount</option>
                                <option value="equalToZero">Amount = 0</option>
                            </select>
                        </div>

                        {loading ? (
                            <div className="d-flex justify-content-center">
                                <Loader />
                            </div>
                        ) : (
                            <>
                                <div className="table-responsive">
                                    <table className="table table-hover table-striped table-bordered shadow-sm" style={{ fontSize: '14px' }}>
                                        <thead className="table-dark">
                                            <tr>
                                                <th>SR No.</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Referred Users</th>
                                                <th>Amount</th>
                                                <th className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedAmbassadors.length ? (
                                                paginatedAmbassadors.map((ambassador, index) => (
                                                    <tr key={ambassador.id}>
                                                        <td className="align-middle">{startIndex + index + 1}</td>
                                                        <td className="align-middle">{ambassador.name}</td>
                                                        <td className="align-middle">{ambassador.email}</td>
                                                        <td className="align-middle">{ambassador.phone}</td>
                                                        <td className="align-middle">{ambassador.total_referred_users}</td>
                                                        <td className="align-middle">
                                                            {Array.isArray(ambassador.referrals_list)
                                                                ? ambassador.referrals_list.reduce((total, referral) => total + (referral.total_price || 0), 0)
                                                                : 0}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            <Link to={`/dashboard/ambassadors/${ambassador.id}`} className="btn btn-primary btn-sm">
                                                                <i className="bi bi-eye"></i>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="7" className="text-center">
                                                        No ambassadors found.
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex justify-content-center mt-3">
                                    <Pagination
                                        count={Math.ceil(sortedAmbassadors.length / itemsPerPage)}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        color="primary"
                                        variant='outlined'
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default BrandAmbassadors;
