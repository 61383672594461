

import React, { useEffect, useState } from 'react';
import { Colors } from '../globals/colors';
import { useUser } from '../../Services/UserContext';

import CustomModal from './CustomModal';
import Login from '../../Dashboard/Login';
import Register from '../../Dashboard/Register';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Styles.css';
import GoogleTranslate from './TranslatorComponent';

const Header = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showLogin, setShowLogin] = useState(true);
    const { logout, userData } = useUser();

    useEffect(() => {
        const mainContent = document.getElementById('main-content');
        if (isModalOpen) {
            mainContent.classList.add('blurred');
        } else {
            mainContent.classList.remove('blurred');
        }
    }, [isModalOpen]);

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const toggleComponent = () => {
        setShowLogin(!showLogin);
    };

    const handleProfileClick = () => {
        if (!userData) {
            handleModalOpen();
        } else {
            window.location.href = "/profilescreen";
        }
    };

    return (
        <>
            <header id="site-header" className="fixed-top">
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-lg stroke navbar-enaam">

                        <a className="navbar-brand" href="/">
                            <img src={require("../images/logo.png")} alt="Enaam.pk" title="Enaam" style={{ height: '69px', marginLeft: 10 }} />
                        </a>
                        <div className='col-5 mt-2 text-right d-none d-lg-block d-md-block'>
                            <li className="nav-item d-none d-lg-block border-0 ">
                                <span >
                                    Need help? Contact Us <a href="tel:+924235131693">Call +92-42-35 131 693</a>
                                </span>
                            </li>

                        </div>

                        <button className="navbar-toggler collapsed bg-gradient" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon fa icon-expand fa-bars"></span>
                            <span className="navbar-toggler-icon fa icon-close fa-times"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                            <ul className="navbar-nav ml-lg-auto main-menu mt-2">

                                <li className="nav-item border-0">
                                    <a className="nav-link" href="/prizes">Prizes</a>
                                </li>




                                <li className="nav-item">
                                    <a className="nav-link" href="/winners">Winners</a>
                                </li>
                                <GoogleTranslate />
                                
                                <li className="nav-item dropdown align-items-center border-0">

                                    <div id="userState" className="d-flex align-items-center">
                                        {userData ? (
                                            <>
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                    <img id="user-image" src={require("../images/user-icon.png")} alt="User Profile" title={userData.name} className="rounded-circle" style={{ height: '30px' }} />
                                                    <span>{userData.name}</span>
                                                </a>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <a className="dropdown-item" href="/profilescreen">Profile</a>
                                                    <a className="dropdown-item" href="#" onClick={logout}>Logout</a>
                                                </div>
                                            </>
                                        ) : (
                                            <a className="nav-link" href="#" onClick={handleModalOpen}>
                                                <img id="user-image" src={require("../images/user-icon.png")} alt="User Profile" className="rounded-circle" style={{ height: '30px' }} />
                                                <span>Login</span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li style={{ lineHeight: '1.2', fontSize: '14px' }} className="nav-item d-lg-none d-md-none ">
                                    Need help? Contact Us <a href="tel:+924235131693">Call +92-42-35 131 693</a>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>

                <CustomModal isOpen={isModalOpen} onClose={handleModalClose}>
                    <div>
                        {showLogin ? <Login /> : <Register />}
                        <button
                            onClick={toggleComponent}
                            className="btn btn-link"
                            style={{ cursor: 'pointer', color: Colors.themeColor, fontWeight: 'bold', fontSize: '14px', textDecoration: 'none' }}
                        >
                            {showLogin ? (
                                "Don't have an account?"
                            ) : (
                                <>
                                    <span style={{ color: '#343434', fontWeight: '400' }}>Already have an account?</span> Go to Login
                                </>
                            )}
                        </button>
                    </div>
                </CustomModal>
            </header>
            <div id="main-content"></div>
        </>
    );
};

export default Header;
