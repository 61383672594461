
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../Services/UserContext';
import { Colors } from '../../globals/colors'; 

import FooterCompanyLinks from './FooterCompanyLinks';
import FooterSupportLinks from './FooterSupportLinks';
import FooterSocialLinks from './FooterSocialLinks';
import CartIcon from '../../pages/HomeScreen/Components/CartIcon';
import CustomModal from '../CustomModal'; 
import Login from '../../../Dashboard/Login'; 
import Register from '../../../Dashboard/Register';
const FooterTop = ({ profile }) => {
  const navigate = useNavigate();
  const { userData } = useUser(); 
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [showLogin, setShowLogin] = useState(true); 

  const handleCartClick = () => {
    if (!userData?.token) {
      setIsModalOpen(true); 
      return; 
    }
    navigate('/cart'); 
  };

  const handleModalClose = () => {
    setIsModalOpen(false); 
  };

  const toggleComponent = () => {
    setShowLogin(!showLogin); 
  };

  return (
    <div className="row footer-top-29">
      {profile?.profile === false ? (
        <div />
      ) : (
        <div className="shipping-card text-start mr-5">
          <a onClick={handleCartClick} className="shopping-cart-button">
            <span id="cartQuantity" className="cart-quantity position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger"></span>
            <img src="assets/images/cart.png" alt="" style={{ maxWidth: '100%' }} />
            <span className="hide-on-mobile align-items-center d-flex" style={{ height: 0, padding: 5, paddingBottom: 10, color: "#fff", whiteSpace: 'nowrap' }}>
              <CartIcon />
              -Shopping cart
            </span>
          </a>
        </div>
      )}
      <FooterCompanyLinks />
      <FooterSupportLinks />
      <div className="col-lg-3"></div>
      <FooterSocialLinks />

      <CustomModal isOpen={isModalOpen} onClose={handleModalClose}>
        <div>
          {showLogin ? <Login /> : <Register />}
          <button
            onClick={toggleComponent}
            className="btn btn-link mt-2"
            style={{ cursor: 'pointer', color: Colors.themeColor, fontWeight: 'bold', fontSize: '14px' , textDecoration: 'none' }}
          >
             {showLogin ? (
                                    "Don't have an account?"
                                ) : (
                                    <>
                                        <span style={{ color: '#343434', fontWeight: '400' }}>Already have an account?</span> Go to Login
                                    </>
                                )}
          </button>
        </div>
      </CustomModal>
    </div>
  );
};

export default FooterTop;
