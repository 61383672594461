import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL } from '../../../Services';
import { useUser } from '../../../Services/UserContext.js';

import Header from '../../Components/HeaderComponent';
import Footer from '../../Components/FooterCompnent';
import Loader from '../../Components/LoaderComponent';
import FlipClock from 'flipclock';
import '../../css/Styles.css';
import 'flipclock/dist/flipclock.css';
import 'react-toastify/dist/ReactToastify.css';
import TimerApp from './components/TimerApp.js';

const GameScreen = () => {
    const { userData , getUserByID} = useUser();
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [result, setResult] = useState('');
    const [activeOption, setActiveOption] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(false);
    const clockRef = useRef(null);
    const navigate = useNavigate();

    const handleClose = () => {
        navigate('/');
        setShowModal(false);
    }

    useEffect(() => {
        
        const fetchCartData = async () => {
            try {
                const userResponse = await axios.get(`${BASE_URL}/user/data`, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                    },
                });

                if (userResponse.data?.status === 200) {
                    const user = userResponse.data.user;

                    const cartResponse = await axios.get(`${BASE_URL}/cart`, {
                        headers: {
                            Authorization: `Bearer ${userData.token}`,
                        },
                    });

                    if (cartResponse.data?.status === 200) {
                        const cartData = cartResponse.data.cart;

                    } else {
                        console.error('Failed to fetch cart data:', cartResponse.data);
                    }
                } else {
                    console.error('Failed to fetch user data:', userResponse.data);
                }
            } catch (error) {
                console.error('Error fetching user/cart data:', error);
            }
        };

        fetchCartData();


        const fetchQuestions = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/questions`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                    },
                });
                if (response.data.status === 'Success') {
                    setQuestions(response.data.data);
                    selectRandomQuestion(response.data.data);
                } else {
                    console.error('Failed to fetch questions.');
                }
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };

        fetchQuestions();
    }, [userData]);

    useEffect(() => {
        if (clockRef.current) {
            const clock = new FlipClock(clockRef.current, {
                clockFace: 'HourlyCounter',
                autoStart: false,
                callbacks: {
                    stop: function () {
                        window.location.href = `${BASE_URL}/questions`;
                    }
                }
            });
            clock.setTime(180);
            clock.setCountdown(true);
            clock.start();
        }
    }, [clockRef]);

    const selectRandomQuestion = (questionsList) => {
        if (questionsList.length > 0) {
            const randomIndex = Math.floor(Math.random() * questionsList?.length);
            setCurrentQuestion(questionsList[randomIndex]);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        console.log("Submit button clicked");
    
        let startTime = localStorage.getItem("timerStartTime");
        let formattedTime = '0:00';
        if (startTime) {
            const currentTime = Date.now();
            const elapsed = Math.floor((currentTime - startTime) / 1000);
            const remainingTime = Math.max(120 - elapsed, 0);
    
            const minutes = Math.floor(remainingTime / 60);
            const seconds = remainingTime % 60;
            formattedTime = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    

        } else {
            console.log("No timer start time found in local storage.");
        }
    
        if (selectedOption === '') {
            toast.error("Please select an option");
            return;
        }
    
    
        if (currentQuestion) {
            const isCorrect = currentQuestion.options.some(
                (option) => option.optionTitle === selectedOption && option.correctOption === 'Y'
            );
            setResult(isCorrect ? 'Correct!' : 'Wrong answer!');
            console.log("Is the answer correct?", isCorrect);
    
            try {
                console.log("Updating cart with correctness...");
                await axios.post(
                    `${BASE_URL}/cart/update-is-correct`,
                    { is_correct: isCorrect ? 'yes' : 'no' },
                    { headers: { Authorization: `Bearer ${userData?.token}` } }
                );
    
                console.log("Fetching cart data...");
                const cartResponse = await axios.get(`${BASE_URL}/cart`, {
                    headers: { Authorization: `Bearer ${userData.token}` },
                });
    
                const { total_amount, total_quantity } = cartResponse.data.cart || {};
                console.log("Fetched cart data:", { total_amount, total_quantity });
    
                const createInvoice = async () => {
                    try {
                        const response = await axios.post(
                            `${BASE_URL}/cart/addInvoice`,
                            { 
                                is_correct: isCorrect ? 'yes' : 'no', 
                                answer_time: formattedTime 
                            },
                            { headers: { Authorization: `Bearer ${userData?.token}` } }
                        );
    
                        const invoiceId = response?.data?.invoice?.invoice_id;
                        const ticketId = response?.data?.invoice?.ticket_id;
    
                        console.log("Invoice created successfully, Invoice ID:", invoiceId);
    
                        if (!invoiceId) {
                            console.error('Invoice ID not found in response:', response.data);
                        } else {
                            if (parseFloat(total_amount) === 0 && total_quantity === 1) {
                                await axios.post(
                                    `${BASE_URL}/payment_callback`,
                                    {
                                        invoice_number: invoiceId,
                                        total_amount: total_amount,
                                        payment_response: {}
                                    },
                                    { headers: { Authorization: `Bearer ${userData.token}` } }
                                ).then(res => {
                                    setModalData({
                                        ...res.data,
                                        invoiceId: invoiceId,
                                        ticketId: ticketId,
                                    });
                                    setShowModal(true);
                                    getUserByID();
                                }).catch(error => {
                                    console.error('Error in payment callback:', error);
                                    setShowModal(false);
                                });
                            } else {
                                setShowModal(false);
                                window.location.href = `https://www.dukan.pk/enaam?invoice_id=${invoiceId}`;
                            }
                        }
                    } catch (error) {
                        console.error('Error submitting cart data:', error);
                    }
                };
    
                await createInvoice();
    
                setTimeout(() => {
                    console.log("Resetting question and options...");
                    selectRandomQuestion(questions);
                    setSelectedOption('');
                    setResult('');
                    setActiveOption(null);
                }, 2000);
            } catch (error) {
                console.error('Error processing the request:', error);
            }
        } else {
            console.log("No current question found.");
        }
    };
    
    
    const handleOptionClick = (optionValue) => {
        setSelectedOption(optionValue);
        setActiveOption(optionValue);
    };

    if (!currentQuestion) return <Loader />;
    return (
        <div className="App">
            <ToastContainer />
            <Header />
            <div className="container-fluid col-lg-10 pt-0 p-4">
                <div style={{ height: "10vh" }} />
                      <div  className='d-flex justify-content-end mr-3 timer'>
                         <TimerApp  />
                         </div>
              
                <div className="row">
                    <div className="col-md-4 question-section">
                      
                        <div className="question " style={{ textAlign: "center" }}>
                            <p style={{ width: "70%", fontWeight: "bold", margin: "0px !important" }}>{currentQuestion.title}</p>
                        </div>
                        <div className="question-wrapper">
                            <p className="text-center">Click to select your answer</p>
                            <button type="button" className="btn btn-primary w-100" onClick={handleSubmit}>CONFIRM</button>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="game-wrapper" style={{ padding: 20 }}>
                            <div className="row justify-content-center" style={{ height: "100%" }}>
                                {currentQuestion.options.map((option, index) => (
                                    <div
                                        className="col-sm-6 col-md-5 col-lg-5 mb-3"
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div
                                            className={`glow option-block ${selectedOption === option.optionTitle ? 'selected' : ''
                                                }`}
                                            onClick={() => handleOptionClick(option.optionTitle)}
                                            style={{
                                                width: '100%',
                                                padding: '10px',
                                                borderRadius: '8px',
                                                backgroundColor: selectedOption === option.optionTitle ? '#007bff' : '#f8f9fa',
                                                color: selectedOption === option.optionTitle ? '#fff' : '#000',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                transition: 'background-color 0.3s ease, border-color 0.3s ease',
                                            }}
                                        >
                                            <input
                                                type="radio"
                                                id={`option${index + 1}-${currentQuestion.id}`}
                                                name={`question-${currentQuestion.id}`}
                                                value={option.optionTitle}
                                                checked={selectedOption === option.optionTitle}
                                                onChange={() => setSelectedOption(option.optionTitle)}
                                                style={{ display: 'none' }} 
                                            />
                                            <label
                                                htmlFor={`option${index + 1}-${currentQuestion.id}`}
                                                style={{
                                                    width: '100%',
                                                    alignItems: "center",
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                    fontSize: '1rem',
                                                    padding: '10px',
                                                }}
                                            >
                                                {index + 1 + ".   " + option.optionTitle}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {showModal && (
    <div className="modal-overlay">
        <div className="coupon-card position-relative">
            <button
                type="button"
                className="close-btn"
                onClick={handleClose}
            >
                ×
            </button>
            <div className="text-center">
                <img
                    src={require("../../images/logo.png")}
                    alt="Enaam Logo"
                    className="logo mb-3"
                />
                <h3 className="text-center text-white">
                    Thank you for participating, {userData?.name || 'User'}!
                </h3>
              
                <p className="text-center text-white mt-3 mb-3">
                    Your Ticket Number is: <strong>{modalData?.ticketId || 'N/A'}</strong>
                </p>
                <h5 className="text-center text-white">
                    Stay in touch with us for more updates.
                </h5>
                <div className="coupon-row mt-3">
                    <button
                        type="submit"
                        className="btn btn-dark text-white px-5"
                        onClick={handleClose}
                        style={{ width: "100%" }}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
)}
        </div>
    );
};

export default GameScreen;
