import React, { useState } from "react";
import {
    TextField,
    Button,
    Checkbox,
    Typography,
    Box,
    IconButton,
    InputAdornment,
    FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { BASE_URL } from "../Services";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Register() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [phone, setPhone] = useState("");
    const [otp, setOtp] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [step, setStep] = useState(1);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const validateFields = () => {
        const newErrors = {};

        if (!name) newErrors.name = "Name is required.";
        if (!phone) newErrors.phone = "Phone number is required.";
        else if (phone.length !== 11)
            newErrors.phone = "Phone number must be 11 digits.";
        if (!email) newErrors.email = "Email is required.";
        else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = "Invalid email format.";
        if (!password) newErrors.password = "Password is required.";
        else if (password.length < 8)
            newErrors.password = "Password must be at least 8 characters.";
        if (!passwordConfirmation)
            newErrors.passwordConfirmation = "Please confirm your password.";
        else if (password !== passwordConfirmation)
            newErrors.passwordConfirmation = "Passwords do not match.";
        if (!termsAccepted)
            newErrors.termsAccepted = "You must accept the terms and conditions.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors.
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        if (!validateFields()) return;

        try {
            const response = await axios.post(`${BASE_URL}/register`, {
                name,
                email,
                password,
                password_confirmation: passwordConfirmation,
                phone,
            });

            if (response.data.status === 200) {
                setErrors({});
                setStep(2);
            } else {
                setMessage("");
                toast.error(response.data.message)
                setErrors({ general: response.data.message });
            }
        } catch (error) {
            setErrors({
                general: error.response?.data?.message || "Registration failed. Please try again.",
            });
        }
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/verify-otp`, { phone, otp });
            localStorage.setItem("token", response.data.token);
            toast.success(response.data.message)
            setErrors({});
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            setErrors({ otp: "OTP verification failed. Please try again." });
            setMessage("");
        }
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {step === 1 && (
                <Box
                    component="form"
                    onSubmit={handleRegister}
                    sx={{
                        p: 3,
                        bgcolor: "white",
                        width: "500px",
                    }}
                >
                    <Box textAlign="center" mb={3}>
                        <img
                            src={require('../UserSide/images/logo.png')}
                            alt="Login Icon"
                            style={{ width: '145px', height: '65px' }}
                        />
                    </Box>
                    <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={!!errors.name}
                        helperText={errors.name}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Phone Number"
                        variant="outlined"
                        fullWidth
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        error={!!errors.phone}
                        helperText={errors.phone}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!errors.email}
                        helperText={errors.email}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={!!errors.password}
                        helperText={errors.password}
                        sx={{ mb: 2 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label="Confirm Password"
                        variant="outlined"
                        type={showConfirmPassword ? "text" : "password"}
                        fullWidth
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                        error={!!errors.passwordConfirmation}
                        helperText={errors.passwordConfirmation}
                        sx={{ mb: 2 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowConfirmPassword(!showConfirmPassword)
                                        }
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box display="flex" alignItems="center" >
                        <Checkbox
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                        />
                        <Typography variant="body2">
                            I accept the{" "}
                            <a href="/terms&Conditions" style={{ color: "#1976d2" }}>
                                Terms and Conditions
                            </a>
                        </Typography>
                    </Box>
                    {errors.termsAccepted && (
                        <FormHelperText error>{errors.termsAccepted}</FormHelperText>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ py: 1.5, mt: 1 }}
                    >
                        Register
                    </Button>
                </Box>
            )}

            {step === 2 && (
                <Box
                    component="form"
                    onSubmit={handleVerifyOtp}
                    sx={{
                        p: 3,
                        bgcolor: "white",
                        width: "500px",
                    }}
                >
                    <Typography variant="h5" color="primary" textAlign="center" mb={2}>
                        Verify OTP
                    </Typography>
                    <TextField
                        label="OTP"
                        variant="outlined"
                        fullWidth
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        error={!!errors.otp}
                        helperText={errors.otp}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ py: 1.5 }}
                    >
                        Verify OTP
                    </Button>
                </Box>
            )}
            <ToastContainer />
        </Box>
    );
}

export default Register;
