
import React, { useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CartComponent } from './Components/CartComponent';
import { ToastContainer, toast } from 'react-toastify';
import { useUser } from '../../../Services/UserContext.js';
import { BASE_URL } from '../../../Services/index.js';
import Header from '../../Components/HeaderComponent';
import Loader from '../../Components/LoaderComponent';
import Footer from '../../Components/FooterCompnent';
import 'react-toastify/dist/ReactToastify.css';

export default function CartScreen() {
    const [cartItems, setCartItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [promoApplied, setPromoApplied] = useState(false);
    const [promoLoading, setPromoLoading] = useState(false);
    const [remainingPrice, setRemainingPrice] = useState(0);
    const [deductedAmount, setDeductedAmount] = useState(0); 
    const navigate = useNavigate();
    const { userData, getCart, updateCart, removeFromCart } = useUser();

   
    const calculateTotalPrice = useCallback((cartData) => {
        return cartData.reduce((total, item) => total + item.price * item.quantity, 0);
    }, []);
    
    
    const updateTotalPrice = useCallback((cartData) => { 
        const newSubtotal = calculateTotalPrice(cartData); 
        const totalWithGst = newSubtotal; 
        setTotalPrice(totalWithGst); 
        setSubtotal(newSubtotal); 
    }, [calculateTotalPrice]);
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const fetchCart = useCallback(async () => {
        if (!userData?.token) {
            return;
        }
        setLoading(false);
        try {
            const data = await getCart(userData?.token);
            if (data?.cart_items && Array.isArray(data.cart_items)) {
                setCartItems(data.cart_items);
                updateTotalPrice(data.cart_items);
            } else {
                setCartItems([]); 
                updateTotalPrice([]); 
            }
        } catch (error) {
            console.error('Error fetching cart:', error);
        } finally {
            setLoading(false);
        }
    }, [userData, getCart, updateTotalPrice]);
    
    useEffect(() => {
        fetchCart();
    }, [fetchCart]);

    const handleQuantityChange = useCallback(async (item, newQuantity) => {
        try {
            const response = await updateCart({ id: item.item_id, quantity: newQuantity });
            if (response.status === 200) {
                const itemIndex = cartItems.findIndex(cartItem => cartItem.item_id === item.item_id);
                if (itemIndex !== -1) {
                    cartItems[itemIndex].quantity = newQuantity;
                    setCartItems([...cartItems]); 
                    updateTotalPrice(cartItems); 
                }
            } else {
                toast.error(response.message || 'Failed to update cart');
            }
        } catch (error) {
            console.error('Error updating cart:', error);
            toast.error('Failed to update cart');
        }
    }, [cartItems, updateCart, updateTotalPrice]);

    const handleDeleteItem = useCallback(async (item) => {
        try {
            const response = await removeFromCart({ id: item.item_id });
            if (response.status === 200) {
                const updatedCartItems = cartItems.filter(cartItem => cartItem.item_id !== item.item_id);
                setCartItems(updatedCartItems); 
                updateTotalPrice(updatedCartItems);
            } else {
                toast.error(response.message || 'Failed to remove item from cart');
            }
        } catch (error) {
            console.error('Error removing item from cart:', error);
        }
    }, [cartItems, removeFromCart, updateTotalPrice]);

    const handleCheckout = useCallback(() => {
        if (!userData?.token) {
            toast.error('Please login to checkout');
        } else if (cartItems.length <= 0) {
            toast.error('Please add items to checkout');
        } else {
            navigate('/initialGameScreen', { state: { cartItems, totalWithGst: totalPrice } });
        }
    }, [userData, navigate, cartItems, totalPrice]);

    const handleApplyPromoCode = async () => {
        if (!promoCode.trim()) {
            toast.error('Please enter a promo code');
            return;
        }
    
        if (!userData?.token) {
            toast.error('Authorization failed. Please log in again.');
            return;
        }
    
        setPromoLoading(true);
        setPromoApplied(false);
        try {
            const response = await axios.post(
                `${BASE_URL}/cart/apply-promo`,
                { promo_code: promoCode },
                { headers: { Authorization: `Bearer ${userData.token}` } }
            );
    
            if (response.data.success || response.data.message?.toLowerCase().includes('success')) {
                toast.success('Promo code applied successfully');
    
                const newSubtotal = parseFloat(response.data.total_price) || 0;
                const discount = parseFloat(response.data.discount) || 0;
                const totalWithGst = newSubtotal ;
    
                setSubtotal(newSubtotal);
                setTotalPrice(totalWithGst);
                setRemainingPrice(totalWithGst.toFixed(2));
                setDeductedAmount(discount.toFixed(2));
    
                setPromoApplied(true);
                setPromoCode('');
    
                updateTotalPrice(response.data.cart_items);
    
            }
        } catch (error) {
            console.error('Error applying promo code:', error);
            toast.error(error.response?.data?.message );
        } finally {
            setPromoLoading(false);
        }
    };
    

    return (
        <div>
            <Header />
            <ToastContainer />
            <section className="home-header inner-page">
                {loading ? (
                    <Loader />
                ) : (
                    <div className="row">
                        <div className="col-md-8 col-lg-9">
                            <div
                                id="cart-container"
                                className="sub-info glow"
                                style={{ padding: 10, justifyContent: 'center' }}
                            >
                                {cartItems.length <= 0 ? (
                                    <div
                                        style={{
                                            justifyContent: 'center',
                                            display: 'flex',
                                            padding: 45,
                                        }}
                                    >
                                        <p>Your cart is empty</p>
                                    </div>
                                ) : (
                                    cartItems.map((item) => (
                                        <CartComponent
                                            key={item.item_id}
                                            item={item}
                                            increaseQuantity={() => handleQuantityChange(item, item.quantity + 1)}
                                            decreaseQuantity={() => handleQuantityChange(item, item.quantity - 1)}
                                            deleteItem={() => handleDeleteItem(item)}
                                        />
                                    ))
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3">
                            <div className="price-cart glow">
                                <div className="price-cart-wrapper">
                                    <div>
                                        <h4 className="price-heading text-left">Total</h4>
                                        <span className="inclusive">(Exclusive Of Tax)</span>
                                    </div>
                                    <div id="subtotal" className="value-price">
                                        RS. {(subtotal / 1.16).toFixed(2)} 
                                    </div>
                                </div>
                                <div>
                                    <hr className="my-2 solid bg-dark" />
                                </div>
                                <div className="price-cart-wrapper text-left mt-3">
                                    <div>
                                        <h5 className="">Subtotal </h5>
                                        <span className="inclusive text-primary">
                                            (include 16% Tax)
                                        </span>
                                    </div>
                                    <div id="total" className="value-price">
                                        RS. {totalPrice.toFixed(2)}
                                    </div>
                                </div>

                                <div className="promo mt-3 d-flex">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Promo code"
                                        value={promoCode}
                                        onChange={(e) => setPromoCode(e.target.value)}
                                        style={{ borderRadius: '10px 0px 0px 10px' }}
                                    />
                                     <button
                                        onClick={handleApplyPromoCode}
                                        className="btn btn-primary col-4 text-center"
                                        disabled={promoLoading}
                                        style={{ borderRadius: '0px 10px 10px 0px' }}
                                    >
                                        {promoLoading ? 'Applying...' : 'Apply'}
                                    </button>
                                </div>

                                <div>
                                    <div className="checkout">
                                    <button
                                    className="btn btn-primary mt-3 w-100"
                                    onClick={handleCheckout} 
                                >
                                    Checkout
                                </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
            <Footer />
        </div>
    );
}
